exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-the-app-index-tsx": () => import("./../../../src/pages/about-the-app/index.tsx" /* webpackChunkName: "component---src-pages-about-the-app-index-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-templates-guides-landing-index-tsx": () => import("./../../../src/templates/GuidesLanding/index.tsx" /* webpackChunkName: "component---src-templates-guides-landing-index-tsx" */),
  "component---src-templates-guides-post-index-tsx": () => import("./../../../src/templates/GuidesPost/index.tsx" /* webpackChunkName: "component---src-templates-guides-post-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/Home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-mega-category-page-index-tsx": () => import("./../../../src/templates/MegaCategoryPage/index.tsx" /* webpackChunkName: "component---src-templates-mega-category-page-index-tsx" */),
  "component---src-templates-sub-category-page-index-tsx": () => import("./../../../src/templates/SubCategoryPage/index.tsx" /* webpackChunkName: "component---src-templates-sub-category-page-index-tsx" */),
  "component---src-templates-system-page-index-tsx": () => import("./../../../src/templates/SystemPage/index.tsx" /* webpackChunkName: "component---src-templates-system-page-index-tsx" */),
  "component---src-templates-tips-post-index-tsx": () => import("./../../../src/templates/TipsPost/index.tsx" /* webpackChunkName: "component---src-templates-tips-post-index-tsx" */)
}

