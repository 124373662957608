import './src/styles/global.css';

const isArr = (val: unknown): val is unknown[] => Array.isArray(val);

interface WindowWithGtmDataLayer extends Window {
  dataLayer: unknown[];
}

const addGoogleTagManagerRouteUpdateEvent = () => {
  if (isArr((window as any as WindowWithGtmDataLayer).dataLayer)) {
    (window as any as WindowWithGtmDataLayer).dataLayer.push({
      event: 'gatsby-route-change',
    });
  }
};

export const onRouteUpdate = () => {
  addGoogleTagManagerRouteUpdateEvent();
};
